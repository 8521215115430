@font-face {
  font-family: "Pohadkovylesfont-Regular";
  src: local("Pohadkovylesfont-Regular"),
    url(./fonts/Pohadkovylesfont-Regular.ttf) format("ttf"),
    url(./fonts/Pohadkovylesfont-Regular.woff) format("woff"),
    url(./fonts/Pohadkovylesfont-Regular.woff2) format("woff2");
}

.App {
  font-family: Pohadkovylesfont-Regular;
}
